import React, { Component, useState, useEffect, useCallback } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { DetailsList, IconButton, PrimaryButton, SelectionMode, Dialog, DialogType, DialogFooter, DefaultButton, Text } from '@fluentui/react';
import { AnnouncementEditor } from './AnnouncementEditor';
import { AnnouncementPanel } from './AnnouncementPanel';
import { useBoolean } from "@fluentui/react-hooks";

const stackTokens = {
  childrenGap: 4,

};

export const AnnouncementManager = (params) => {

 
  const [teams, setTeams] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [kpiTypes, setKpiTypes] = useState([]);
  const [runIntervals, setRunIntervals] = useState([]);
  const [token, setToken] = useState(null);

  const [annoucementsLoaded, setAnnouncementsLoaded] = useState(false);
  const [kpiTypesLoaded, setKpiTypesLoaded] = useState(false);
  const [runIntervalsLoaded, setRunIntervalsLoaded] = useState(false);
  const [managerLoaded, setManagerLoaded] = useState(false);
  const [teamsLoaded, setTeamsLoaded] = useState(false);
  const [manager, setManager] = useState("");
  const [loading, setLoading] = useState(true);
  const [editedAnnouncement, setEditedAnnouncement] = useState(null);


  const { instance, accounts, inProgress } = useMsal();

  // Delete dialog state
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [deleteDialogProps, setDeleteDialogProps] = useState(
    {
      type: DialogType.normal,
      title: "Title Not Set",
      closeButtonAriaLabel: 'Close',
      subText: "Content not set."
    });
  const [announcementToDelete, setAnnouncementToDelete] = useState(null);

  // Makes sure the user is authenticated, and gets the users token before starting the load of data.
  useEffect(() => {
    if (inProgress == InteractionStatus.None && token == null) {
      const tokenRequest = { account: accounts[0], scopes: ["api://603214e7-1034-40ff-98ce-dbedea6997ff/Application.Access"] };
      instance.acquireTokenSilent(tokenRequest).then((response) => {
        setToken(tkn => response.accessToken);

        console.log("Token acquired: " + response.accessToken);
      }).catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(tokenRequest).then((response) => {
            setToken(tkn => response.accessToken);
            //getMembers(response.accessToken);
          });
        }
        else {
          console.log(error)
        }
      });
    }
  }, [instance, inProgress, token, accounts]);

  // Load manager id once the token is loaded and only if the manager id has not been loaded.
  useEffect(() => {
    if (token && !managerLoaded) {
      fetch('api/Department/GetManager', { headers: { Authorization: `Bearer ${token}` } }).then(async (response) => {
        var data = await response.json();
        setManager(manager => data);
        setManagerLoaded(managerLoaded => true);
      }).catch((error) => {
        console.error(error);
      });
    }
  }, [token, managerLoaded, setManager, setManagerLoaded])

  
  useEffect(() => {
    if (token && !teamsLoaded) {
      fetch('api/Department/GetAvailableDepartments', { headers: { Authorization: `Bearer ${token}` } }).then(async (response) => {
        var data = await response.json();
       setTeams(teams=>data);
       setTeamsLoaded(teamsLoaded=>true)
      }).catch((error) => {
        console.error(error);
      });
    }
  }, [token, setTeams, setTeamsLoaded]);

  // Load run intervals once the token is loaded and only if the run intervals have not been loaded.
  useEffect(() => {
    if (token && !runIntervalsLoaded) {
      fetch('api/TeamKpi/GetRunIntervals', { headers: { Authorization: `Bearer ${token}` } }).then(async (response) => {
        var data = await response.json();
        setRunIntervals(runIntervals => data);
        setRunIntervalsLoaded(runIntervalsLoaded => true);
      }).catch((error) => {
        console.error(error);
      });
    }
  }, [token, runIntervalsLoaded, setRunIntervals, setRunIntervalsLoaded]);

  // Load KPI types once the token is loaded and only if the KPI types have not been loaded.
  useEffect(() => {
    if (token && !kpiTypesLoaded) {
      fetch('api/TeamKpi/GetAvailableKpis', { headers: { Authorization: `Bearer ${token}` } }).then(async (response) => {
        var data = await response.json();
        setKpiTypes(kpiTypes => data);
        setKpiTypesLoaded(kpiTypesLoaded => true);
      }).catch((error) => {
        console.error(error);
      });
    }
  }, [token, kpiTypesLoaded, setKpiTypes, setKpiTypesLoaded]);

  // Load announcements once the token and manager id have been loaded if the announcements have not been loaded.
  useEffect(() => {
    if (token && managerLoaded && !annoucementsLoaded) {
      fetch('api/Announcement/GetAnnouncements', { headers: { Authorization: `Bearer ${token}` } }).then(async (response) => {
        var data = await response.json();

        setAnnouncements(announcements => data);
        setAnnouncementsLoaded(annoucementsLoaded => true);

      }).catch((error) => {
        console.error(error);
      });
    }
  }, [token, managerLoaded, annoucementsLoaded, setAnnouncements, setAnnouncementsLoaded]);


  function addOrReplaceAnnouncement(orig, newAnnouncement, announcements) {
    // Removes orig from the list and replaces it waith newAnnouncement. 
    // this will prevent the announcement with the id of 0 from remaining once
    // it has been saved.
    var newAnnouncements = [...announcements.filter((el) => el.id != orig.id), newAnnouncement];
    return newAnnouncements;
  }

  const saveAnnouncement = useCallback(async (announcement) => {
    var output = JSON.stringify(announcement, null, 4);

    if (announcement.id === 0) {
      // Add Announcement
      fetch('api/Announcement/AddAnnouncement',
        {
          method: "POST", body: JSON.stringify(announcement),
          headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        }
      ).then(async (response) => {
        if (response.status == 200) {
          var data = await response.json();
          var newAnnouncements = addOrReplaceAnnouncement(announcement, data, announcements);
          setAnnouncements(announcements => newAnnouncements);
          setEditedAnnouncement(editedAnnouncement => null)
        } else {
          console.error(response);
        }
      }).catch((error) => {
        console.error(error);
      });
    } else {
      // Update Announcement
      fetch('api/Announcement/UpdateAnnouncement',
        {
          method: "PUT", body: JSON.stringify(announcement),
          headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }
        }
      ).then(async (response) => {
        if (response.status == 200) {
          var data = await response.json();
          var newAnnouncements = addOrReplaceAnnouncement(announcement, data, announcements);
          setAnnouncements(announcements => newAnnouncements);
          setEditedAnnouncement(editedAnnouncement => null);
        } else {
          console.error(response);
        }
      }).catch((error) => {
        console.error(error);
      });
    }
  }, [announcements, setAnnouncements, setEditedAnnouncement, token])


  const deleteAnnouncement = useCallback(async (announcement) => {
    setAnnouncementToDelete(announcement);
    toggleHideDialog();
    setDeleteDialogProps(deleteDialogProps => {
      return {
        type: DialogType.normal,
        title: `Delete \"${announcement.name}\"?`,
        closeButtonAriaLabel: 'Close',
        subText: `Are you sure you wish to delete \" ${announcement.name}\"?`,
      }
    }
    );
  }, [setAnnouncementToDelete, toggleHideDialog, setDeleteDialogProps]);

  const performDeleteAnnouncement = useCallback(async (announcement) => {

    if (announcement.id === 0) {
      return;
    }

    fetch('api/Announcement/DeleteAnnouncement/' + announcement.id, {
      method: "DELETE", headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }
    }).then(async (response) => {
      if (response.status == 200) {
        var data = await response.json();
        if (data) {
          var newAnnouncements = [...announcements.filter((el) => el.id != announcement.id)];
          setAnnouncements(announcements => newAnnouncements);
          setEditedAnnouncement(editedAnnouncement => null);
          toggleHideDialog();
          setAnnouncementToDelete(null);
        }
      } else {
        console.error(response);
      }
    }).catch((error) => {
      console.error(error);
    });
  }, [announcements, setAnnouncements, setEditedAnnouncement, setAnnouncementToDelete, toggleHideDialog, token])

  const editAnnouncement = useCallback(async (id) => {
    var announcement = announcements.find((el) => el.id == id);
    setEditedAnnouncement(editedAnnouncement => announcement);
  }, [setEditedAnnouncement, announcements])

  const newAnnouncement = useCallback(async () => {
    var newItem = { id: 0, name: "", webHookUri: "", reportsTo: manager.memberId, reportsToUpn: manager.userPrincipalName, onlyForSelectedMembers: false, excludedMembers: [], configuredIntervals: [] };
    setEditedAnnouncement(editedAnnouncement => newItem);
  }, [setAnnouncements]);

  const getIntervalName = (interval) => {
    var runInterval = runIntervals.find((el) => el.id == interval.runIntervalId);
    return runInterval.name;
  }

  const getAnnouncmentIntervals = (announcement) => {
    var intervals = announcement.configuredIntervals.map((interval) => getIntervalName(interval)).join(", ");
    return intervals;
  }

  const announcementColumns = [
    { key: 'column1', name: 'Name', fieldName: 'name', minWidth: 100, flexGrow: 1, isResizable: true },
    {
      key: 'intervalCol',
      name: 'Intervals',
      minWidth: 100,
      flexGrow: 1,
      isResizable: true,
      onRender: (item) => {
        return <span style={{ textOverflow: "ellipsis" }}>
          {getAnnouncmentIntervals(item)}
        </span>
      }
    },
    {
      key: 'column2',
      name: 'Edit',
      minWidth: 44,
      flexGrow: 1,
      isResizable: true,
      onRender: (item) => {
        return <IconButton
          iconProps={{ iconName: "Edit" }}
          onClick={
            () => {
              editAnnouncement(item.id);
            }
          } />
      }
    },
    {
      key: 'column3',
      name: 'Delete',
      minWidth: 44,
      flexGrow: 1,
      isResizable: true,
      onRender: (item) => {
        return <IconButton
          iconProps={{ iconName: "Delete" }}
          onClick={
            () => {
              deleteAnnouncement(item)
            }
          }
        />
      }
    }
  ];
  //  Set loaded to true when all of the required data has been loaded. 
  useEffect(() => {
    setLoading(loading => !(annoucementsLoaded && teamsLoaded && kpiTypesLoaded && runIntervalsLoaded && managerLoaded));
  }, [annoucementsLoaded, teamsLoaded, kpiTypesLoaded, runIntervalsLoaded, managerLoaded, setLoading]);

  return (<div>
    <h2>Announcements</h2>
    <div>
      {editedAnnouncement == null &&
        <div>
          <div style={{ paddingBottom: "10px" }}>
            <Text variant='large' >
              Below is a list of Announcements you have defined for your teams. Each announcement can be configured to run at different intervals, each of which
              can be configured to include multiple KPIs that are supported for the interval.  Not all KPIs are supported for all intervals.  You also have the
              to select members to exclude from the announcement in order to allow you to create announcements for your different teams.
            </Text>
          </div>
          <div>
            <Text variant="large">To view or edit an announcement, click the edit button next to the announcement.  To delete the announcment, click the delete button next to it.</Text>
          </div>
          <DetailsList
            items={announcements}
            columns={announcementColumns}
            enableUpdateAnimations={true}
            selectionMode={SelectionMode.none}
          />
          <PrimaryButton iconProps={{ iconName: "Add" }} onClick={newAnnouncement} >New Announcement</PrimaryButton></div>

      }
      {editedAnnouncement != null &&
        <div>
          <AnnouncementEditor
            announcement={editedAnnouncement}
            runIntervals={runIntervals}
            kpiTypes={kpiTypes}
            onSaveAnnouncement={saveAnnouncement}
            onDelete={deleteAnnouncement}
            teams={teams}
            token={token}
            onCancel={() => { setEditedAnnouncement(editedAnnouncement => null) }} />
        </div>
        // <div>{announcements.length > 0 && announcements.map((obj) => {
        //   return (
        //     <AnnouncementPanel key={"aedit" + obj.id} announcement={obj}>
        //       <AnnouncementEditor
        //         announcement={obj}
        //         runIntervals={runIntervals}
        //         kpiTypes={kpiTypes}
        //         onSaveAnnouncement={saveAnnouncement}
        //         onDelete={deleteAnnouncement}
        //         teamMembers={teamMembers}
        //         onCancel={() => { alert("Cancelling!") }}
        //       />
        //     </AnnouncementPanel>)
        // })}</div>
      }
    </div>
    <Dialog
      hidden={hideDialog}
      onDismiss={toggleHideDialog}
      dialogContentProps={deleteDialogProps}
    >
      <DialogFooter>
        <PrimaryButton onClick={() => { performDeleteAnnouncement(announcementToDelete) }}>Delete</PrimaryButton>
        <DefaultButton onClick={() => { toggleHideDialog(); }}>Cancel</DefaultButton>
      </DialogFooter>
    </Dialog>
  </div >)
}