import { AnnouncementManager } from "./components/Announcements/AnnouncementManager";
import { Home } from "./components/Home";
import { AppInfo } from "./components/AppInfo/AppInfo";
import { OldestTicketRouter } from "./components/oldestTickets/OldestTicketRouter";
import { StaleTicketRouter } from "./components/staleTickets/StaleTicketRouter";
import { SlaTicketRouter } from "./components/slaTickets/SlaTicketRouter";
import { OnTimeBudgetTicketRouter } from "./components/onTimeBudgetTickets/OnTimeBudgetTicketRouter";
const AppRoutes = [

  {
    index: true,
    path: '/',
    element: <Home />
  },
  {
    path: '/announcements/manage',
    element: <AnnouncementManager />
  },
  {
    path: '/staleTickets',
    element: <StaleTicketRouter />
  },
  {
    path: '/oldestTickets',
    element: <OldestTicketRouter />
    },
    {
        path: '/slaTickets',
        element: <SlaTicketRouter />
    },
    {
        path: '/onTimeBudgetTickets',
        element: <OnTimeBudgetTicketRouter />
    },
  {
    path: '/appInfo',
    element: <AppInfo />
  }
];

export default AppRoutes;
