import React, { useState, useEffect } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { DetailsList, SelectionMode, TooltipHost } from '@fluentui/react';

export const KpiList = (params) => {
    const kpiColumns = [
        {
            key: 'name',
            name: 'Name',
            fieldName: 'kpiName',
            minWidth: 100,
            maxWidth: 400,
            isResizable: true,
            onRender: (item) => {
                return (
                    <TooltipHost
                        content={item.kpiName}
                        tooltipProps={{
                            onRenderContent: () => {
                                return (<div>{item.kpiName}<p>{item.kpiDescription}</p></div>)
                            }
                        }}
                    >
                        <span>{item.kpiName}</span>
                    </TooltipHost >
                )
            }

        },

        { key: 'value', name: 'Value', fieldName: 'formattedValue', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'startDate', name: 'Start', fieldName: 'startDate', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'endDate', name: 'End', fieldName: 'endDate', minWidth: 100, maxWidth: 200, isResizable: true },


    ];
    var hasKpis = params.kpis && params.kpis.length > 0;
    var groups = [];
    if (hasKpis) {
        var lastStartDate = "";
        var lastEndDate = "";
        var currCount = 0;
        var grpInc = 1;
        var currKpi = null;
        for (var i = 0; i < params.kpis.length; i++) {
            var kpi = currKpi = params.kpis[i];

            // every time startDate changes add a new group
            if (kpi.startDate != lastStartDate) {
                if (lastStartDate != "") {
                    groups.push({ key: "grp" + grpInc, name: lastStartDate + ((kpi.interval != "Daily") ? " thru " + lastEndDate : ""), startIndex: i - currCount, count: currCount });
                }
                lastStartDate = kpi.startDate;
                lastEndDate = kpi.endDate;
                currCount = 0;
                grpInc++;
            }
            currCount++;

        }
        // push last group to groups
        groups.push({ key: "grp" + grpInc, name: lastStartDate + ((currKpi.interval != "Daily") ? " thru " + lastEndDate : ""), startIndex: params.kpis.length - currCount, count: currCount });
    }
    return (
        <div>
            {hasKpis &&

                <DetailsList items={params.kpis} groups={groups} columns={kpiColumns} selectionMode={SelectionMode.none} />
            }
        </div>
    )
};