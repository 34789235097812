import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useEffect } from "react";

export const App = ()=>{
//static displayName = App.name;
  const {instance, inProgress} = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect();
    }
  }, [isAuthenticated, inProgress, instance]);

  return ( <Layout>
    <AuthenticatedTemplate>
      <Routes>
        {AppRoutes.map((route, index) => {
          const { element, ...rest } = route;
          return <Route key={index} {...rest} element={element} />;
        })}
      </Routes>
    </AuthenticatedTemplate>

    <UnauthenticatedTemplate>
        You are not logged in.
    </UnauthenticatedTemplate>
  </Layout>)
};
// export default class App extends Component {
//   static displayName = App.name;

//   render() {
//     return (

     
//     );
//   }
// }
