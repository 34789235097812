import React, { useState, useEffect } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";

export const AppInfo = (props) => {

    const [token, setToken] = useState(null);
    const [envName, setEnvName] = useState("Unknown");
    const [envVars, setEnvVars] = useState([]);
    const [status, setStatus] = useState("Ok");
    const { instance, accounts, inProgress } = useMsal();
    const [configText, setConfigText] = useState("Not Loaded.");
    useEffect(() => {
        if (inProgress == InteractionStatus.None && token == null) {
            const tokenRequest = { account: accounts[0], scopes: ["api://603214e7-1034-40ff-98ce-dbedea6997ff/Application.Access"] };
            instance.acquireTokenSilent(tokenRequest).then((response) => {
                setToken(tkn => response.accessToken);

                console.log("Token acquired: " + response.accessToken);
            }).catch(async (error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(tokenRequest).then((response) => {
                        setToken(tkn => response.accessToken);
                        //getMembers(response.accessToken);
                    });
                }
                else {
                    console.log(error)
                }
            });
        }
    }, [instance, inProgress, token, accounts]);

    useEffect(() => {
        if (token != null) {
            fetch('api/AppInfo/GetEnvironment', { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(async (response) => {
                var data = await response.text();
                setEnvName(e => data);
            }).catch((error) => {
                console.error(error);
                setStatus(s => error);
            });
        }
    }, [token, setEnvName, setStatus]);
    useEffect(() => {
        if (token != null) {
            fetch('api/AppInfo/GetEnvironmentVars', { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(async (response) => {
                var data = await response.json();
                setEnvVars(data)
                console.log(data);
            }).catch((error) => {
                console.error(error);
                setStatus(s => error);
            });

        }
    }, [token, setEnvVars, setStatus]);
    useEffect(() => {

        if (token != null) {
            fetch('api/AppInfo/GetConfig', { headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" } }).then(async (response) => {
                var data = await response.text();
                setConfigText(data);
            }).catch((error) => {
                console.error(error);
                setStatus(s => error);
            });
        }
    }, [token, setConfigText, setStatus])
    return (
        <div>
            <h1>Application Info</h1>
            <div>
                <a style={{ padding: "20px" }} href="appInfo#EnvVars">Environment Variables</a>
                <a style={{ padding: "20px" }} href="appInfo#Config">Configuration</a>
            </div>
            <div>Environment: {envName}</div>
            <div>Load Status: {status}</div>
            <h2 id="EnvVars">Environment Variables:</h2>
            <div>
                {Object.entries(envVars).map(([k, v], index) => {
                    return (<div key={`evar${index}`}>{k} = {v}</div>);
                })}
            </div>
            <h2 id="Config">Configuration:</h2>
            <div>
                <pre>{configText}</pre>
            </div>
        </div>
    );
}