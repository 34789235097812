import React, { Component, useState, useEffect, useCallback } from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { StaleTicketDirectorViewer } from './StaleTicketDirectorViewer';
import { StaleTicketViewer } from './StaleTicketViewer';

export const StaleTicketRouter = () => {
    const [isTeamLeader, setIsTeamLeader] = useState(false);
    const [token, setToken] = useState(null);
    const { instance, accounts, inProgress } = useMsal();


    useEffect(() => {
        if (inProgress == InteractionStatus.None && token == null) {
            const tokenRequest = { account: accounts[0], scopes: ["api://603214e7-1034-40ff-98ce-dbedea6997ff/Application.Access"] };

            instance.acquireTokenSilent(tokenRequest).then((response) => {
                setToken(tkn => response.accessToken);

                console.log("Token acquired: " + response.accessToken);
            }).catch(async (error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(tokenRequest).then((response) => {
                        setToken(tkn => response.accessToken);
                        //getMembers(response.accessToken);
                    });
                }
                else {
                    console.log(error)
                }
            });
        }
    }, [instance, inProgress, token, accounts, setToken]);

    useEffect(() => {
        if (token) {
            fetch('api/Department/GetIsManager', { headers: { Authorization: `Bearer ${token}` } }).then(async (response) => {
                var data = await response.json();
                setIsTeamLeader(isTeamLeader => data);
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [token, setIsTeamLeader]);

    if (isTeamLeader) {
        return (<StaleTicketDirectorViewer />);
    } else {
        return (<StaleTicketViewer />);
    }
}
