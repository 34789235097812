import React, { useState} from 'react';
import {Stack, IconButton, Callout } from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';

const stackTokens = {
    childrenGap: 4,
    maxWidth: 300,
  };
  const labelCalloutStackStyles = { root: { padding: 20 } };
  const iconButtonStyles = { root: { marginBottom: -3 } };
export const IconLabel = (props) => 
{
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const descriptionId= useId('description');
    const iconButtonId= useId('iconButton');
  
    return (
      <>
        <Stack horizontal verticalAlign="center" tokens={stackTokens}>
          <span id={props.id}>{props.label}</span>
          <IconButton
            id={iconButtonId}
            iconProps={props.iconProps}
            title={props.title}
            ariaLabel={props.ariaLabel}
            onClick={toggleIsCalloutVisible}
            styles={iconButtonStyles}
          />
        </Stack>
        {isCalloutVisible && (
          <Callout
            target={'#' + iconButtonId}
            setInitialFocus
            onDismiss={toggleIsCalloutVisible}
            ariaDescribedBy={descriptionId}
            role="alertdialog"
          >
            <Stack tokens={stackTokens} horizontalAlign="start" styles={labelCalloutStackStyles}>
                {props.description}
            </Stack>
          </Callout>
        )}
      </>
    );
  };