import React, { useCallback, useState, useEffect } from 'react';
import { Dropdown, DefaultButton, PrimaryButton, Stack, TextField, Checkbox, Text } from '@fluentui/react';
import { IconLabel } from './InfoLabel';
const infoIcon = { iconName: 'Info' };
const closeIcon = { iconName: 'Cancel' };

const stackTokens = {
    childrenGap: 8,
};
const kpiStackTokens = {
    childrenGap: 4,
    padding: "0px 0px 0px 20px"
}

export const AnnouncementEditor = (props) => {
    const onSaveAnnouncement = props.onSaveAnnouncement;
    const onDelete = props.onDelete;
    const [announcement, setAnnouncement] = useState(props.announcement);

    const [runIntervals, setRunIntervals] = useState(props.runIntervals || []);
    const [tmpIntervals, setTmpIntervals] = useState([]); // Used to track changes before saving
    const [kpiTypes, setKpiTypes] = useState(props.kpiTypes); // Used to track changes before saving
    const [token, setToken] = useState(props.token); // Used to track changes before saving
    const [teams, setTeams] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]); // Used to track changes before saving

    const [selectedTeam, setSelectedTeam] = useState("");
    useEffect(() => {
        console.log("props.token changed");
        setToken(props.token);
    }, [props.token])
    useEffect(() => {
        console.log("props.teams changed")
        var options = props.teams.map(t => { return { key: t, text: t } });
        setTeams(options);
    }, [props.teams])

    useEffect(()=>{
        setRunIntervals(props.runIntervals);
        console.log("Setting runIntervals from effect.")
    }, [props.runIntervals])

    useEffect(() => {
        console.log("props.announcement changed")
        setAnnouncement(props.announcement);
    }, [props.announcement])

    var getTeamMembers = useCallback((teamId) => {
        fetch("/api/Department/GetDepartmentMembers/" + teamId, { headers: { Authorization: "Bearer " + token } }).then(
            async r => {
                var data = await r.json();
                setTeamMembers(data);
            }

        )
    }, [setTeamMembers]);
    useEffect(() => {
        if (token) {
            if (announcement.department) {
                getTeamMembers(announcement.department);
            }
        }
    }, [announcement.department, token])


    const toggleInterval = useCallback((runIntervalId) => {
        var existingItem = announcement.configuredIntervals.find(i => i.runIntervalId === runIntervalId);
        if (existingItem) {
            // item currently exists, cache it in tmpIntervals and 
            //remove it from announcement.configuredIntervals
            setTmpIntervals([...tmpIntervals, existingItem]);
            setAnnouncement(a => { return { ...a, configuredIntervals: a.configuredIntervals.filter(i => i.runIntervalId !== runIntervalId) } });
        } else {
            // if interval exists in tmpIntervals, remove it from tmpIntervals 
            //and add it to announcement.configuredIntervals otherwise add a new run interval to announcement.configuredIntervals
            var tmpItem = tmpIntervals.find(i => i.runIntervalId === runIntervalId);
            if (tmpItem) {
                setTmpIntervals(tmpIntervals.filter(i => i.runIntervalId !== runIntervalId));
                setAnnouncement(a => { return { ...a, configuredIntervals: [...a.configuredIntervals, tmpItem] } });
            } else {
                setAnnouncement(a => { return { ...a, configuredIntervals: [...a.configuredIntervals, { id: 0, runIntervalId: runIntervalId, kpis: [] }] } });
            }
        }

    }, [announcement, tmpIntervals, setTmpIntervals, setAnnouncement]);

    const toggleKpi = useCallback((runIntervalId, kpiIdentifier) => {
        var interval = announcement.configuredIntervals.find(i => i.runIntervalId === runIntervalId);
        if (interval) {
            if (!interval.kpis){
                interval.kpis=[];
            }
            var kpi = interval.kpis.find(k => k.kpiIdentifier === kpiIdentifier);
            if (kpi) {
                // remove kpi from interval.configuredKpis
                setAnnouncement(a => {
                    return {
                        ...a, configuredIntervals: a.configuredIntervals.map(i => {
                            if (i.runIntervalId === runIntervalId) {
                                return { ...i, kpis: i.kpis.filter(k => k.kpiIdentifier !== kpiIdentifier) };
                            } else {
                                return i;
                            }
                        })
                    }
                });
            } else {
                // add kpi to interval.configuredKpis
                setAnnouncement(a => {
                    return {
                        ...a, configuredIntervals: a.configuredIntervals.map(i => {
                            if (i.runIntervalId === runIntervalId) {
                                return { ...i, kpis: [...i.kpis, { id: 0, kpiIdentifier: kpiIdentifier }] };
                            } else {
                                return i;
                            }
                        })
                    }
                });
            }
        }
    }, [announcement, setAnnouncement]);

    const toggleTeamKpi = useCallback((runIntervalId, kpiIdentifier) => {
        var interval = announcement.configuredIntervals.find(i => i.runIntervalId === runIntervalId);
        if (interval) {
            if (!interval.teamKpis){
                interval.teamKpis=[];
            }
            var kpi = interval.teamKpis.find(k => k.kpiIdentifier === kpiIdentifier);
            if (kpi) {
                // remove kpi from interval.configuredKpis
                setAnnouncement(a => {
                    return {
                        ...a, configuredIntervals: a.configuredIntervals.map(i => {
                            if (i.runIntervalId === runIntervalId) {
                                return { ...i, teamKpis: i.teamKpis.filter(k => k.kpiIdentifier !== kpiIdentifier) };
                            } else {
                                return i;
                            }
                        })
                    }
                });
            } else {
                // add kpi to interval.configuredKpis
                setAnnouncement(a => {
                    return {
                        ...a, configuredIntervals: a.configuredIntervals.map(i => {
                            if (i.runIntervalId === runIntervalId) {
                                return { ...i, teamKpis: [...i.teamKpis, { id: 0, kpiIdentifier: kpiIdentifier }] };
                            } else {
                                return i;
                            }
                        })
                    }
                });
            }
        }
    }, [announcement, setAnnouncement]);

    const toggleExcludedMember = useCallback((upn) => {
        var existingItem = announcement.excludedMembers.find(m => m.userPrincipalName === upn)
        var member = teamMembers.find(m => m.userPrincipalName === upn);
        if (existingItem) {
            // item currently exists, remove it from announcement.excludedMembers
            setAnnouncement(a => { return { ...a, excludedMembers: a.excludedMembers.filter(m => m.userPrincipalName !== upn) } });
        } else {
            // add member to announcement.excludedMembers
            setAnnouncement(a => { return { ...a, excludedMembers: [...a.excludedMembers, { userPrincipalName: member.userPrincipalName, memberId: member.memberId, fullName: member.fullName, email: member.email }] } });
        }
    }, [announcement, setAnnouncement, teamMembers]);

    const isExcludedMember = useCallback((memberId) => {
        return announcement.excludedMembers.find(m => m.userPrincipalName === memberId) !== undefined;
    }, [announcement]);
    // determine if the specified run interval exists in announcement.configuredIntervals 

    const isIntervalChecked = useCallback((runIntervalId) => {
        return announcement.configuredIntervals.find(i => i.runIntervalId === runIntervalId) !== undefined;
    }, [announcement]);

    const isKpiChecked = useCallback((runIntervalId, kpiIdentifier) => {
        var interval = announcement.configuredIntervals.find(i => i.runIntervalId === runIntervalId);
        if (interval && interval.kpis) {
            return interval.kpis.find(k => k.kpiIdentifier === kpiIdentifier) !== undefined;
        }
        return false;
    }, [announcement]);
    const isTeamKpiChecked = useCallback((runIntervalId, kpiIdentifier) => {
        var interval = announcement.configuredIntervals.find(i => i.runIntervalId === runIntervalId);
        if (interval && interval.teamKpis) {
            return interval.teamKpis.find(k => k.kpiIdentifier === kpiIdentifier) !== undefined;
        }
        return false;
    }, [announcement]);

    const saveAnnouncement = () => {
        // Do save then call callback
        onSaveAnnouncement(announcement);
    }

    const deleteAnnouncement = () => {
        onDelete(announcement);
    }
    const getIntervalName = useCallback((runIntervalId) => {
        var interval = runIntervals.find(i => i.id === runIntervalId);
        return interval ? interval.name : "Unknown";
    }, [runIntervals]);

    // Get KPIs that are available for the given interval
    const getIntervalMemberKpiTypes = useCallback((runIntervalId) => {
        var results = kpiTypes.filter(k => k.supportedIntervals.includes(runIntervalId) && k.supportsMember);
        return results;
    }, [runIntervals, kpiTypes]);

    const getIntervalTeamKpiTypes = useCallback((runIntervalId) => {
        var results = kpiTypes.filter(k => k.supportedIntervals.includes(runIntervalId) && k.supportsTeam);
        return results;
    }, [runIntervals, kpiTypes]);

    const onTeamSelected = useCallback((evt, team) => {
        setSelectedTeam(team.key);
    }, [selectedTeam, setSelectedTeam]);




    // Change the label of the editor based on the current state.
    var editorAction = "Loading Editor";
    if (announcement) {
        if (announcement.id === 0) {
            editorAction = "Creating New Announcement";
        } else {
            editorAction = "Editing Announcement: " + announcement.name;
        }
    }
    return (<div><h3>{editorAction}</h3>
        <div>
            <Stack tokens={stackTokens}>
                <TextField label="Name" placeholder="Name to identify the announcement" value={announcement.name} onChange={(e, v) => setAnnouncement(a => { return { ...a, name: v } })}
                    onRenderLabel={(props) => { return (<IconLabel iconProps={infoIcon} description={"This is the name of the announcement. Name your announcements so that you and your team members can understand their purpose."} {...props} />) }} />
                <Dropdown options={teams} label="Team" placeholder="Select a team" selectedKey={announcement.department} onChange={(e, o) => setAnnouncement(a => { return { ...a, department: o.key } })} />
                <TextField
                    placeholder="Web Hook URI for teams incoming web hook"
                    label="Web Hook URI"
                    value={announcement.webHookUri}
                    onChange={(e, v) => setAnnouncement(a => { return { ...a, webHookUri: v } })}
                    onRenderLabel={(props) => { return (<IconLabel iconProps={infoIcon} description={"This is the URI provided by Teams when you create an incoming web hook. Please see the Team KPI Engine documentation in It-Glue for more information."} {...props} />) }} />
                {(teamMembers && teamMembers.length > 0) &&
                    <div>
                        <h4>Excluded Members</h4>
                        <Text variant="large">Members selected here will not be included in the announcement.  Using exclusions, you can create different announcement for different teams.</Text>
                        {teamMembers && teamMembers.map((member, index) => {
                            return (<Checkbox
                                label={member.fullName}
                                key={"em" + member.userPrincipalName}
                                onChange={() => toggleExcludedMember(member.userPrincipalName)}
                                checked={isExcludedMember(member.userPrincipalName)}
                            />);
                        })}
                        <h4>Intervals</h4>
                        <div style={{ paddingBottom: "10px" }}>
                            <Text variant="large">Select the intervals at which you'd like the announcement to display.
                                Once you have selected an interval, a list of KPIs available for that interval will be displayed beneath
                                it, allowing you to choose the KPIs you which to include in the announcement for that interval.
                            </Text>
                        </div>
                        <div>
                            <Text variant="large">Announcements will be generated on the first day of the next interval.
                                For instance a daily announcment generated today will display data from yesterday.
                                A weekly announcement will contain the KPIs for the prior week ,from Sunday to Saturday.
                                Monthly announcements will be made containing data from the prior month on the first of the month.
                                Quarterly announcements will be made on the first day of January, April, July and October for the prior 3 months.
                                Yearly announcements will be made on January 1st. </Text>
                        </div>
                        {runIntervals.map((interval, index) => {
                            return (
                                <div key={'itrvl' + interval.id}>
                                    <Checkbox
                                        checked={isIntervalChecked(interval.id)}
                                        onChange={() => toggleInterval(interval.id)}
                                        label={interval.name}
                                        onRenderLabel={(props) => { return (<IconLabel iconProps={infoIcon} description={interval.description} {...props} />) }}
                                    />
                                    {isIntervalChecked(interval.id) &&
                                        <Stack tokens={kpiStackTokens}>
                                            <h5>Member Kpis</h5>
                                            {getIntervalMemberKpiTypes(interval.runIntervalTypeId).map((kpiType) => {
                                                return (<Checkbox
                                                    key={"kpit" + interval.runIntervalTypeId + "-" + kpiType.kpiIdentifier}
                                                    label={kpiType.name}
                                                    checked={isKpiChecked(interval.runIntervalTypeId, kpiType.kpiIdentifier)}
                                                    onChange={() => { toggleKpi(interval.runIntervalTypeId, kpiType.kpiIdentifier) }}

                                                />);
                                            })}
                                            <h5>Team Kpis</h5>
                                            {getIntervalTeamKpiTypes(interval.runIntervalTypeId).map((kpiType) => {
                                                return (<Checkbox
                                                    key={"tkpit" + interval.runIntervalTypeId + "-" + kpiType.kpiIdentifier}
                                                    label={kpiType.name}
                                                    checked={isTeamKpiChecked(interval.runIntervalTypeId, kpiType.kpiIdentifier)}
                                                    onChange={() => { toggleTeamKpi(interval.runIntervalTypeId, kpiType.kpiIdentifier) }}

                                                />);
                                            })}
                                        </Stack>
                                    }
                                </div>)
                        })}
                        {/* {announcement.configuredIntervals.map((interval) => {
                    return (<div key={"ci" + interval.runIntervalId}><h5>{getIntervalName(interval.runIntervalId)}</h5>
                        <Stack tokens={stackTokens}>
                            {getIntervalKpiTypes(interval.runIntervalId).map((kpiType) => {
                                return (<Checkbox key={"kpit" + interval.runIntervalId + "-" + kpiType.kpiIdentifier} label={kpiType.name} checked={isKpiChecked(interval.runIntervalId, kpiType.kpiIdentifier)} onChange={() => { toggleKpi(interval.runIntervalId, kpiType.kpiIdentifier) }} />);
                            })}
                        </Stack>
                    </div>);
                })} */}
                    </div>}
                <Stack horizontal>
                    <PrimaryButton text="Save" iconProps={{ iconName: "Save" }} onClick={saveAnnouncement} />
                    <DefaultButton text="Cancel" iconProps={{ iconName: "Cancel" }} onClick={props.onCancel} />
                    <DefaultButton text="Delete" iconProps={{ iconName: 'Delete' }} onClick={deleteAnnouncement} />
                </Stack>
            </Stack>
        </div>
    </div>);
}