import React, { useState, useEffect } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { KpiList } from './KpiDisplay/KpiList.js';
export const Home = (params) => {

    const { instance, accounts, inProgress } = useMsal();
    const [token, setToken] = useState(null);
    const [memberKpis, setMemberKpis] = useState(null);
    // Makes sure the user is authenticated, and gets the users token before starting the load of data.
    useEffect(() => {
        if (inProgress == InteractionStatus.None && token == null) {
            const tokenRequest = { account: accounts[0], scopes: ["api://603214e7-1034-40ff-98ce-dbedea6997ff/Application.Access"] };
            instance.acquireTokenSilent(tokenRequest).then((response) => {
                setToken(tkn => response.accessToken);

                console.log("Token acquired: " + response.accessToken);
            }).catch(async (error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(tokenRequest).then((response) => {
                        setToken(tkn => response.accessToken);
                        //getMembers(response.accessToken);
                    });
                }
                else {
                    console.log(error)
                }
            });
        }
    }, [instance, inProgress, token, accounts]);
    useEffect(() => {

        if (token) {
            fetch('api/TeamKpi/GetMemberKpis', { headers: { Authorization: `Bearer ${token}` } }).then(async (response) => {
                var data = await response.json();
                setMemberKpis(data);
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [token, setMemberKpis])

    return (
        <div>
            <h1>Team KPI Engine</h1>
            {memberKpis &&
                <div>
                    <h2>Your Kpis</h2>
                    <h3>Daily</h3>
                    <KpiList kpis={memberKpis.dailyKpis} />
                    <h3>Weekly</h3>
                    <KpiList kpis={memberKpis.weeklyKpis} />
                    <h3>Monthly</h3>
                    <KpiList kpis={memberKpis.monthlyKpis} />
                    <h3>Yearly</h3>
                    <KpiList kpis={memberKpis.yearlyKpis} />
                </div>
            }
        </div>
    )

}