import React, { useState, useEffect } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { DetailsList, DetailsRow, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react';

const columns = [
    {
        key: 'column1',
        name: 'Ticket #',
        ariaLabel: 'Ticket number',
        fieldName: 'srServiceRecId',
        minWidth: 50,
        maxWidth: 70,
        data: 'number',
        isResizable: true,
    },
    {
        key: 'column2',
        name: 'Company',
        fieldName: 'companyName',
        isResizable: true,
        minWidth: 140,
        maxWidth: 200,

        data: 'string',
        isPadded: true,
    },
    {
        key: 'column3',
        name: 'Summary',
        fieldName: 'summary',
        isResizable: true,
        isMultiline: true,

        data: 'string',
        isPadded: true,
    },
    {
        key: 'column4',
        name: 'Status',
        fieldName: 'statusDescription',
        isMultiline: true,


        isResizable: true,
        minWidth: 140,
        maxWidth: 190,

        data: 'string',
        isPadded: true,
    },
    {
        key: 'column5',
        name: 'Ticket Entered',
        fieldName: 'ticketEnteredDate',
        minWidth: 80,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: 'number',
        onRender: (item) => {
            return <span>{new Intl.DateTimeFormat().format(Date.parse(item.ticketEnteredDate))}</span>;
        },

        isPadded: true,
    },
    {
        key: 'column6',
        name: 'Last Time Entry',
        fieldName: 'lastTimeEntry',
        minWidth: 80,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: 'number',
        onRender: (item) => {
            return <span>{new Intl.DateTimeFormat().format(Date.parse(item.lastTimeEntry))}</span>;
        },

        isPadded: true,
    },
    {
        key: 'column7',
        name: 'Days Since Update',
        fieldName: 'numberOfDaysOfLastTicketEntry',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        isSorted: true,
        isCollapsible: true,
        data: 'string',
        isSortedDescending: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        isPadded: true,
    },

];

export const StaleTicketDirectorViewer = (params) => {
    const [token, setToken] = useState(null);
    const [staleTickets, setStaleTickets] = useState([]);
    const [staleTicketsMembers, setStaleTicketsMembers] = useState([]);
    const [groups, setGroups] = useState([]);
    const { instance, accounts, inProgress } = useMsal();
    const [numOverSeven, setnumOverSeven] = useState([]);


    function setGroupsGenerator(memTickets) {
        //var num = 0;
        memTickets.sort((a, b) => a.memberId - b.memberId).reduce((acc, cur) => {
            const { memberId, fullName } = cur;
            
            const group = {
                key: memberId,
                name: `${fullName}`,
                startIndex: 0,
                count: 1,
                isCollapsed: true,
            };
            if (acc.length === 0) {
                acc.push(group);
                //return acc;
            } else if (acc[acc.length - 1].key !== cur.memberId) {
                const { count, startIndex } = acc[acc.length - 1];
                acc.push({
                    ...group,
                    startIndex: count + startIndex,
                });
                //return acc;
            }
            else {
                acc[acc.length - 1].count++;
            }

            //if (cur.numberOfDaysOfLastTicketEntry >= 7) {
            //    num++;
                
            //}

            setGroups(groups => acc);
            //setnumOverSeven(numOverSeven => num);
            return acc;
        }, []);
    }

    function countNumOfTicketsOverSeven(memTickets) {
       // var statusToExcludeInCount = ["Closed", "Completed", "On Hold", "Waiting Parts/Repair", "Parts Partially Received", "Resolved", "Cancelled"]
        let uniqueTickets = [...new Map(memTickets.map((item) => [item["srServiceRecId"], item])).values(),];
        var num = 0;
        for (let i = 0; i < uniqueTickets.length; i++) {
            let item = uniqueTickets[i];
           // if (statusToExcludeInCount.indexOf(item.statusDescription) === -1) {
                if (item.numberOfDaysOfLastTicketEntry >= 7) {
                    num++;
                }
            //}

            
        }
        setnumOverSeven(numOverSeven => num);
    }


    // Get the access token for internal requests.
    useEffect(() => {
        if (inProgress == InteractionStatus.None && token == null) {
            const tokenRequest = { account: accounts[0], scopes: ["api://603214e7-1034-40ff-98ce-dbedea6997ff/Application.Access"] };
            instance.acquireTokenSilent(tokenRequest).then((response) => {
                setToken(tkn => response.accessToken);
                console.log("Token acquired: " + response.accessToken);
            }).catch(async (error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(tokenRequest).then((response) => {
                        setToken(tkn => response.accessToken);
                    });
                }
                else {
                    console.log(error)
                }
            });
        }
    }, [instance, inProgress, token, accounts]);

    useEffect(() => {
        if (token) {
            fetch('api/CwData/GetStaleTicketMember', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(async (response) => {
                var data = await response.json();
                setStaleTickets(staleTickets => data)

            }).catch((error) => {
                console.error(error);
            });
            fetch('api/CwData/GetStaleTicketsDirector', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(async (response) => {
                var data = await response.json();
                setStaleTicketsMembers(staleTicketsMembers => data)
                setGroupsGenerator(data);
                countNumOfTicketsOverSeven(data);
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [token, setStaleTickets])

    const _renderRow = (props, defaultRender) => {
        console.log(props);
        const sRecId = props.item.srServiceRecId;
        //var url = `https://mycloud.net-cloud.com/v4_6_release/services/system_io/Service/fv_sr100_request.rails?service_recid=${sRecId}&companyName=Reabah`;
        var url = `https://mycloud.net-cloud.com/?company=REABAH&locale=en_US&routeTo=ServiceFV&srRecID=${sRecId}&fullscreen=false&chat=false`;
        return (
            <DetailsRow {...props} onClick={() => window.open(url, '_blank')} />
        );
    }
    const hasAccount = accounts.length > 0;
    const onRenderDetailsHeader = (headerProps, defaultRender) => {
        if (!headerProps || !defaultRender) {
            //technically these may be undefined...
            return null;
        }
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsHeader-cell': {
                            whiteSpace: 'normal',
                            textOverflow: 'clip',
                            lineHeight: 'normal',
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                        },
                    },
                },
            },
        })
    }
    return (
        <div>
            <div>
            
            <h2>{hasAccount && accounts[0].name + "'s "}Current Stale Tickets</h2>
            <DetailsList
                columns={columns}
                items={staleTickets}
                selectionMode={SelectionMode.none}
                    onRenderRow={_renderRow}
                    onRenderDetailsHeader={onRenderDetailsHeader}></DetailsList>
            </div>
            <div>
                <h2>All Members Current Stale Tickets</h2>
                <span>Number of Unique Tickets over 7 days - {numOverSeven }</span>
                <DetailsList
                    columns={columns}
                    items={staleTicketsMembers}
                    groups={groups}
                    selectionMode={SelectionMode.none}
                    groupProps={{
                        showEmptyGroups: true,
                    }}
                    onRenderRow={_renderRow}
                    onRenderDetailsHeader={onRenderDetailsHeader}></DetailsList>
            </div>
        </div>

    );

};