import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import {App} from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import {LogLevel} from "@azure/msal-browser"
import { initializeIcons } from '@fluentui/font-icons-mdl2';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const rUrl = window.location.protocol + '//' + window.location.host;
const msalConfiguration = {
  auth: {
    clientId: "603214e7-1034-40ff-98ce-dbedea6997ff",
    authority: "https://login.microsoftonline.com/d3de0d00-8a93-4045-9b9a-e19b9fbdbe2b",
    redirectUri: rUrl
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  },
  system: {
    loggerOptions: {
        logLevel: LogLevel.Verbose,
        loggerCallback: (level, message, containsPii) => {
            
            switch (level) {
                case LogLevel.Error:
                    console.error(message);
                    return;
                case LogLevel.Info:
                    console.info(message);
                    return;
                case LogLevel.Verbose:
                    console.debug(message);
                    return;
                case LogLevel.Warning:
                    console.warn(message);
                    return;
            }
        },
        piiLoggingEnabled: false
    },
},
};

const msalInstance = new PublicClientApplication(msalConfiguration);
initializeIcons();
root.render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>
  </MsalProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
