import React, { useState, useEffect } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { DetailsList, DetailsRow, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react';

const columns = [
    {
        key: 'column1',
        name: 'Ticket #',
        ariaLabel: 'Ticket number',
        fieldName: 'srServiceRecId',
        minWidth: 50,
        maxWidth: 70,
        data: 'number',
        isResizable: true,
    },
    {
        key: 'column2',
        name: 'Company',
        fieldName: 'companyName',
        isResizable: true,
        minWidth: 140,
        maxWidth: 200,
        isMultiline: true,
        data: 'string',
        isPadded: true,
    },
    {
        key: 'column3',
        name: 'Summary',
        fieldName: 'summary',
        isResizable: true,
        isMultiline: true,

        data: 'string',
        isPadded: true,
    },
    {
        key: 'column4',
        name: 'SLA Priority',
        fieldName: 'slaPriority',


        isMultiline: true,
        isResizable: true,
        minWidth: 130,
        maxWidth: 170,

        data: 'string',
        isPadded: true,
    },
    {
        key: 'column5',
        name: 'Met Responded',
        fieldName: 'metRespondedSla',
        minWidth: 50,
        maxWidth: 60,
        isResizable: true,
        isCollapsible: true,
        data: 'string',


        isPadded: true,
    },
    {
        key: 'column6',
        name: 'Met Resolved',
        fieldName: 'metResolvedSla',
        minWidth: 35,
        maxWidth: 50,
        isResizable: true,
        isCollapsible: true,
        data: 'string',


        isPadded: true,
    },
    {
        key: 'column7',
        name: 'Date Closed',
        fieldName: 'dateClosed',
        minWidth: 55,
        maxWidth: 70,
        isResizable: false,
        isSorted: true,
        isCollapsible: true,
        data: 'number',
        isSortedDescending: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        isPadded: true,
        onRender: (item) => {
            return <span>{new Intl.DateTimeFormat().format(Date.parse(item.dateClosed))}</span>;
        },
    },

];

export const SlaTicketViewer = (params) => {
    const [token, setToken] = useState(null);
    const [slaTickets, setSlaTickets] = useState([]);
    const { instance, accounts, inProgress } = useMsal();


    const [indivTickets, setindivTickets] = useState([]);
    const [indivresolved, setindivresolved] = useState([]);
    const [indivresponded, setindivresponded] = useState([]);
    const [indivresolvedPercent, setindivresolvedPercent] = useState([]);
    const [indivrespondedPercent, setindivrespondedPercent] = useState([]);

    function countSlaInStatistics(memTickets) {
        // var statusToExcludeInCount = ["Closed", "Completed", "On Hold", "Waiting Parts/Repair", "Parts Partially Received", "Resolved", "Cancelled"]
        let uniqueTickets = [...new Map(memTickets.map((item) => [item["srServiceRecId"], item])).values(),];
        var totalnum = 0;
        var responded = 0;
        var resolved = 0
        for (let i = 0; i < uniqueTickets.length; i++) {
            let item = uniqueTickets[i];
            totalnum++;
            // if (statusToExcludeInCount.indexOf(item.statusDescription) === -1) {
            if (item.metRespondedSla === 'Yes') {
                responded++;
            }
            if (item.metResolvedSla === 'Yes') {
                resolved++;
            }
            //}


        }



        let respondedPercent = ((responded / totalnum).toFixed(2) * 100);
        let resolvedPercent = ((resolved / totalnum).toFixed(2) * 100);

        setindivTickets(indivTickets => totalnum);
        setindivresolved(indivresolved => resolved);
        setindivresponded(indivresponded => responded);
        setindivresolvedPercent(indivresolvedPercent => resolvedPercent);
        setindivrespondedPercent(indivrespondedPercent => respondedPercent);
    }

    // Get the access token for internal requests.
    useEffect(() => {
        if (inProgress == InteractionStatus.None && token == null) {
            const tokenRequest = { account: accounts[0], scopes: ["api://603214e7-1034-40ff-98ce-dbedea6997ff/Application.Access"] };
            instance.acquireTokenSilent(tokenRequest).then((response) => {
                setToken(tkn => response.accessToken);
                console.log("Token acquired: " + response.accessToken);
            }).catch(async (error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(tokenRequest).then((response) => {
                        setToken(tkn => response.accessToken);
                    });
                }
                else {
                    console.log(error)
                }
            });
        }
    }, [instance, inProgress, token, accounts]);

    useEffect(() => {
        if (token) {
            fetch('api/CwData/GetSlaTicketMember', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(async (response) => {
                var data = await response.json();
                setSlaTickets(slaTickets => data)
                countSlaInStatistics(data);
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [token, setSlaTickets])

    const _renderRow = (props, defaultRender) => {
        console.log(props);
        const sRecId = props.item.srServiceRecId;
        //var url = `https://mycloud.net-cloud.com/v4_6_release/services/system_io/Service/fv_sr100_request.rails?service_recid=${sRecId}&companyName=Reabah`;
        var url = `https://mycloud.net-cloud.com/?company=REABAH&locale=en_US&routeTo=ServiceFV&srRecID=${sRecId}&fullscreen=false&chat=false`;
        return (
            <DetailsRow {...props} onClick={() => window.open(url, '_blank')} />
        );
    }
    const hasAccount = accounts.length > 0;
    const onRenderDetailsHeader = (headerProps, defaultRender) => {
        if (!headerProps || !defaultRender) {
            //technically these may be undefined...
            return null;
        }
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsHeader-cell': {
                            whiteSpace: 'normal',
                            textOverflow: 'clip',
                            lineHeight: 'normal',
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                        },
                    },
                },
            },
        })
    }
    // todo: Load sla tickets for the current user.
    return (
        <div>
            <h2>{hasAccount && accounts[0].name + "'s "}Closed SLA Tickets In Past 3 Months</h2>
            <span>Responded: {indivrespondedPercent}% ({indivresponded}), Resolved: {indivresolvedPercent}% ({indivresolved}), Total: ({indivTickets})</span>

            <DetailsList
                columns={columns}
                items={slaTickets}
                selectionMode={SelectionMode.none}
                onRenderRow={_renderRow}
                onRenderDetailsHeader={onRenderDetailsHeader}></DetailsList>
        </div>

    );

};