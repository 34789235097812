import React, { useState, useEffect } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { DetailsList, DetailsRow, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react';

const columns = [
    {
        key: 'column1',
        name: 'Ticket #',
        ariaLabel: 'Ticket number',
        fieldName: 'srServiceRecId',
        minWidth: 50,
        maxWidth: 70,
        data: 'number',
        isResizable: true,
    },
    {
        key: 'column2',
        name: 'Company',
        fieldName: 'companyName',
        isResizable: true,
        minWidth: 140,
        maxWidth: 200,
        isMultiline: true,
        data: 'string',
        isPadded: true,
    },
    {
        key: 'column3',
        name: 'Summary',
        fieldName: 'summary',
        isResizable: true,
        isMultiline: true,

        data: 'string',
        isPadded: true,
    },
    {
        key: 'column4',
        name: 'Met Due Date',
        fieldName: 'metDueDate',

        isResizable: true,
        minWidth: 40,
        maxWidth: 60,

        data: 'string',
        isPadded: true,
    },
    {
        key: 'column5',
        name: 'Date Required',
        fieldName: 'dateRequired',
        minWidth: 50,
        maxWidth: 80,
        isResizable: false,

        isCollapsible: true,
        data: 'number',


        isPadded: true,
        //onRender: (item) => {
        //    return <span>{
        //        new Intl.DateTimeFormat().format(Date.parse(item.dateRequired))}</span>;
        //},
    },
    {
        key: 'column6',
        name: 'Date Completed',
        fieldName: 'dateCompleted',
        minWidth: 60,
        maxWidth: 80,
        isResizable: false,

        isCollapsible: true,
        data: 'number',
        isSorted: true,
        isSortedDescending: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        isPadded: true,
        onRender: (item) => {
            return <span>{new Intl.DateTimeFormat().format(Date.parse(item.dateCompleted))}</span>;
        },
    },
    {
        key: 'column7',
        name: 'Met Budget',
        fieldName: 'metBudget',

        isResizable: true,
        minWidth: 40,
        maxWidth: 60,

        data: 'string',
        isPadded: true,
    },
    {
        key: 'column8',
        name: 'Budget',
        fieldName: 'hoursBudget',
        minWidth: 60,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: 'number',


        isPadded: true,
    },
    {
        key: 'column9',
        name: 'Actual',
        fieldName: 'actualHours',
        minWidth: 60,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: 'number',


        isPadded: true,
    },


];

export const OnTimeBudgetTicketDirectorViewer = (params) => {
    const [token, setToken] = useState(null);
    const [onTimeBudgetTickets, setOnTimeBudgetTickets] = useState([]);
    const [onTimeBudgetTicketsMembers, setOnTimeBudgetTicketsMembers] = useState([]);
    const { instance, accounts, inProgress } = useMsal();
    const [groups, setGroups] = useState([]);
    const [totalTickets, setTotalTickets] = useState([]);
    const [totalOnTime, setTotalOnTime] = useState([]);
    const [totalOnBudget, setTotalOnBudget] = useState([]);
    const [totalOnTimePercent, setTotalOnTimePercent] = useState([]);
    const [totalOnBudgetPercent, setTotalOnBudgetPercent] = useState([]);

    const [indivTickets, setindivTickets] = useState([]);
    const [indivOnTime, setindivOnTime] = useState([]);
    const [indivOnBudget, setindivOnBudget] = useState([]);
    const [indivOnTimePercent, setindivOnTimePercent] = useState([]);
    const [indivOnBudgetPercent, setindivOnBudgetPercent] = useState([]);



    function setGroupsGenerator(memTickets) {
        //var num = 0;
        memTickets.sort((a, b) => a.memberId - b.memberId).reduce((acc, cur) => {
            const { memberId, fullName } = cur;

            const group = {
                key: memberId,
                name: `${fullName}`,
                startIndex: 0,
                count: 1,
                isCollapsed: true,
            };
            if (acc.length === 0) {
                acc.push(group);
                //return acc;
            } else if (acc[acc.length - 1].key !== cur.memberId) {
                const { count, startIndex } = acc[acc.length - 1];
                acc.push({
                    ...group,
                    startIndex: count + startIndex,
                });
                //return acc;
            }
            else {
                acc[acc.length - 1].count++;
            }

            

            setGroups(groups => acc);
         
            return acc;
        }, []);
    }

    function countOverallStatistics(memTickets) {
        // var statusToExcludeInCount = ["Closed", "Completed", "On Hold", "Waiting Parts/Repair", "Parts Partially Received", "Resolved", "Cancelled"]
        let uniqueTickets = [...new Map(memTickets.map((item) => [item["srServiceRecId"], item])).values(),];
        var totalnum = 0;
        var onTime = 0;
        var onBudget = 0
        for (let i = 0; i < uniqueTickets.length; i++) {
            let item = uniqueTickets[i];
            totalnum++;
            // if (statusToExcludeInCount.indexOf(item.statusDescription) === -1) {
            if (item.metDueDate === 'Yes') {
                onTime++;
            }
            if (item.metBudget === 'Yes') {
                onBudget++;
            }
            //}


        }



        let respondedPercent = ((onTime / totalnum).toFixed(2) * 100);
        let resolvedPercent = ((onBudget / totalnum).toFixed(2) * 100);

        setTotalTickets(totalTickets => totalnum);
        setTotalOnTime(totalOnTime => onBudget);
        setTotalOnBudget(totalOnBudget => onTime);
        setTotalOnTimePercent(totalOnTimePercent => resolvedPercent);
        setTotalOnBudgetPercent(totalOnBudgetPercent => respondedPercent);
    }

    function countIndivStatistics(memTickets) {
        // var statusToExcludeInCount = ["Closed", "Completed", "On Hold", "Waiting Parts/Repair", "Parts Partially Received", "Resolved", "Cancelled"]
        let uniqueTickets = [...new Map(memTickets.map((item) => [item["srServiceRecId"], item])).values(),];
        var indivnum = 0;
        var onTime = 0;
        var onBudget = 0
        for (let i = 0; i < uniqueTickets.length; i++) {
            let item = uniqueTickets[i];
            indivnum++;
            // if (statusToExcludeInCount.indexOf(item.statusDescription) === -1) {
            if (item.metDueDate === 'Yes') {
                onTime++;
            }
            if (item.metBudget === 'Yes') {
                onBudget++;
            }
            //}


        }
        let respondedPercent = ((onTime / indivnum).toFixed(2) * 100);
        let resolvedPercent = ((onBudget / indivnum).toFixed(2) * 100);

        setindivTickets(indivTickets => indivnum);
        setindivOnTime(indivOnTime => onBudget);
        setindivOnBudget(indivOnBudget => onTime);
        setindivOnTimePercent(indivOnTimePercent => resolvedPercent);
        setindivOnBudgetPercent(indivOnBudgetPercent => respondedPercent);
    }
    // Get the access token for internal requests.
    useEffect(() => {
        if (inProgress == InteractionStatus.None && token == null) {
            const tokenRequest = { account: accounts[0], scopes: ["api://603214e7-1034-40ff-98ce-dbedea6997ff/Application.Access"] };
            instance.acquireTokenSilent(tokenRequest).then((response) => {
                setToken(tkn => response.accessToken);
                console.log("Token acquired: " + response.accessToken);
            }).catch(async (error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(tokenRequest).then((response) => {
                        setToken(tkn => response.accessToken);
                    });
                }
                else {
                    console.log(error)
                }
            });
        }
    }, [instance, inProgress, token, accounts]);

    useEffect(() => {
        if (token) {
            fetch('api/CwData/GetOnTimeBudgetTicketMember', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(async (response) => {
                var data = await response.json();
                setOnTimeBudgetTickets(onTimeBudgetTickets => data)
                countIndivStatistics(data);
            }).catch((error) => {
                console.error(error);
            });
            fetch('api/CwData/GetOnTimeBudgetTicketsDirector', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(async (response) => {
                var data = await response.json();
                setOnTimeBudgetTicketsMembers(onTimeBudgetTicketsMembers => data)
                setGroupsGenerator(data);
                countOverallStatistics(data);
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [token, setOnTimeBudgetTickets])

    const _renderRow = (props, defaultRender) => {
        console.log(props);
        const sRecId = props.item.srServiceRecId;
        //var url = `https://mycloud.net-cloud.com/v4_6_release/services/system_io/Service/fv_sr100_request.rails?service_recid=${sRecId}&companyName=Reabah`;
        var url = `https://mycloud.net-cloud.com/?company=REABAH&locale=en_US&routeTo=ServiceFV&srRecID=${sRecId}&fullscreen=false&chat=false`;
        return (
            <DetailsRow {...props} onClick={() => window.open(url, '_blank')} />
        );
    }
    const hasAccount = accounts.length > 0;
    const onRenderDetailsHeader = (headerProps, defaultRender) => {
        if (!headerProps || !defaultRender) {
            //technically these may be undefined...
            return null;
        }
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsHeader-cell': {
                            whiteSpace: 'normal',
                            textOverflow: 'clip',
                            lineHeight: 'normal',
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                        },
                    },
                },
            },
        })
    }

    return (
        <div>
            <div>
                <h2>{hasAccount && accounts[0].name + "'s "}Closed On Time/Budget Tickets In Past 3 Months</h2>
                <span>On Time: {indivOnTimePercent}% ({indivOnTime}), On Budget: {indivOnBudgetPercent}% ({indivOnBudget}), indiv: ({indivTickets})</span>

                <DetailsList
                    columns={columns}
                    items={onTimeBudgetTickets}
                    selectionMode={SelectionMode.none}
                    onRenderRow={_renderRow}
                    onRenderDetailsHeader={onRenderDetailsHeader}>
                </DetailsList>
            </div>
            <div>
                <h2>All Members Closed On Time/Budget Tickets for Past 3 Months</h2>
                <span>Overall Statistics (Unique Tickets) - On Time: {totalOnTimePercent}% ({totalOnTime}), On Budget: {totalOnBudgetPercent}% ({totalOnBudget}), Total: ({totalTickets})</span>
                <DetailsList
                    columns={columns}
                    items={onTimeBudgetTicketsMembers}
                    groups={groups}
                    selectionMode={SelectionMode.none}
                    groupProps={{
                        showEmptyGroups: true,
                    }}
                    onRenderRow={_renderRow}
                    onRenderDetailsHeader={onRenderDetailsHeader}>
                </DetailsList>
            </div>

        </div>

    );

};