import React, { Component, useState, useEffect, useCallback } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";


export const NavMenu = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const [token, setToken] = useState(null);
    const [isTeamLeader, setIsTeamLeader] = useState(false);
    const [isDeveloper, setIsDeveloper] = useState(false);
    const { instance, accounts, inProgress } = useMsal();

    const toggleNavbar = () => setCollapsed(!collapsed);
    useEffect(() => {
        if (inProgress == InteractionStatus.None && token == null) {
            const tokenRequest = { account: accounts[0], scopes: ["api://603214e7-1034-40ff-98ce-dbedea6997ff/Application.Access"] };
            if (accounts[0].username == "aalmquist@net-cloud.com" || accounts[0].username == "pditrich@net-cloud.com") {
                setIsDeveloper(true);
            }
            instance.acquireTokenSilent(tokenRequest).then((response) => {
                setToken(tkn => response.accessToken);

                console.log("Token acquired: " + response.accessToken);
            }).catch(async (error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(tokenRequest).then((response) => {
                        setToken(tkn => response.accessToken);
                        //getMembers(response.accessToken);
                    });
                }
                else {
                    console.log(error)
                }
            });
        }
    }, [instance, inProgress, token, accounts]);

    useEffect(() => {
        if (token) {
            fetch('api/Department/GetIsManager', { headers: { Authorization: `Bearer ${token}` } }).then(async (response) => {
                var data = await response.json();
                setIsTeamLeader(isTeamLeader => data);
            }).catch((error) => {
                console.error(error);
            });
        }
    }, [token, setIsTeamLeader]);
    const logout = useCallback(() => {
        if (inProgress == InteractionStatus.None) {
            instance.logoutRedirect();
        }
    }, [instance, accounts, inProgress])
    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                <NavbarBrand tag={Link} to="/"><img style={{ paddingRight: "10px" }} src="/favicon.png" width={50} />Team KPI Engine</NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                    <ul className="navbar-nav flex-grow">
                        {isTeamLeader &&
                            <NavItem>
                                <NavLink tag={Link} className='text-dark' to="/announcements/manage">Announcements</NavLink>

                            </NavItem>
                        }

                        <NavItem>
                            <NavLink tag={Link} className='text-dark' to="/staleTickets">Stale Tickets</NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink tag={Link} className='text-dark' to="/oldestTickets">Oldest Tickets</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className='text-dark' to="/slaTickets">SLA Tickets</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className='text-dark' to="/onTimeBudgetTickets">On Time/Budget Tickets</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className='text-dark' to="" onClick={logout}>Logout</NavLink>
                        </NavItem>

                        {isDeveloper &&
                            <NavItem>
                                <NavLink tag={Link} className='text-dark' to="/appInfo">App Info</NavLink>
                            </NavItem>
                        }

                    </ul>
                </Collapse>
            </Navbar>
        </header>
    );
}
// export class NavMenu extends Component {
//   static displayName = NavMenu.name;

//   constructor(props) {
//     super(props);

//     this.toggleNavbar = this.toggleNavbar.bind(this);
//     this.state = {
//       collapsed: true
//     };
//   }

//   toggleNavbar() {
//     this.setState({
//       collapsed: !this.state.collapsed
//     });
//   }

//   render() {
//     return (
//       <header>
//         <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
//           <NavbarBrand tag={Link} to="/"><img style={{ paddingRight: "10px" }} src="/favicon.png" width={50} />Team KPI Engine</NavbarBrand>
//           <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
//           <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
//             <ul className="navbar-nav flex-grow">
//               <NavItem>
//                 <NavLink tag={Link} className='text-dark' to="/announcements/manage">Announcements</NavLink>

//               </NavItem>
//               <NavItem>
//                 <NavLink tag={Link} className='text-dark' to="/staleTickets">My Stale Tickets</NavLink>
//               </NavItem>
//             </ul>
//           </Collapse>
//         </Navbar>
//       </header>
//     );
//   }
// }
